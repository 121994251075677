<!-- eslint-disable camelcase -->
<template>
  <div>
    <b-modal
      v-model="profilePopup"
      centered
      hide-footer
      class=""
      title="Profile Completed Successfully"
    >
      <div class="row mx-1">
        <b-row class="">
          <h6>
            You have completed your profile successfully. How would you like to
            proceed?
          </h6>
        </b-row>
        <b-row class="my-1">
          <b-col
            v-if="current_event.length > 3"
            cols="12"
            class="w-full"
          >
            <b-button
              block
              icon-pack="feather"
              :to="'/events/' + current_event"
              class="shadow-lg w-full"
              variant="warning"
              type="border"
            >Previously Looked Event</b-button>
          </b-col>
          <b-col
            cols="6"
            class="mt-1"
          >
            <b-button
              block
              icon-pack="feather"
              to="/events-list"
              class="shadow-lg w-full"
              variant="warning"
              type="border"
            >All Events</b-button>
          </b-col>
          <b-col
            cols="6"
            class="mt-1"
          >
            <b-button
              block
              icon-pack="feather"
              variant="warning"
              type="border"
              class="shadow-lg"
              @click="stayOnPage"
            >Stay On This Page</b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <b-modal
      v-model="view_ownership_document_popup"
      centered
      hide-footer
      size="lg"
      title="Resume File"
      @close="ownershipDocumentClosed"
    >
      <div
        class="mx-2 w-full"
        style="height: 100vh!important;"
      >
        <iframe
          v-if="document_type == 'pdf'"
          :src="ownership_document_source"
          width="100%"
          style="height: 100vh!important;"
          frameborder="0"
          content-disposition="inline"
        />

        <iframe
          v-else
          :src="ownership_document_source"
          width="100%"
          style="height: 100vh!important;"
          frameborder="0"
        />
      </div>
    </b-modal>

    <b-modal
      v-model="view_resume_popup"
      centered
      hide-footer
      size="lg"
      title="Resume File"
      @close="resumeClosed"
    >
      <div
        class="mx-2 w-full"
        style="height: 100vh!important;"
      >
        <iframe
          v-if="document_type == 'pdf'"
          :src="file_source"
          width="100%"
          style="height: 100vh!important;"
          frameborder="0"
        />

        <iframe
          v-else
          :src="file_source"
          width="100%"
          style="height: 100vh!important;"
          frameborder="0"
        />
      </div>
    </b-modal>

    <b-modal
			v-if="false"
      v-model="notify_resume_parser"
      centered
      hide-footer
      class=""
      title="Upload your resume"
    >
      <div class="container">
        <div class="vx-row mx-2">
          <h6>
            Our system can help you fill up your education and employment
            information based on your resume. Do you want to upload your resume
            now?
          </h6>
        </div>
        <div class="vx-row my-4 w-full">
          <div class="vx-col my-2 w-full">
            <b-button
              icon-pack="feather"
              class="shadow-lg w-full"
              variant="success"
              type="border"
              @click="goToResume"
            >Upload and extract information from your resume</b-button>
          </div>
          <div class="vx-col my-2 w-full">
            <b-button
              icon-pack="feather"
              variant="warning"
              type="border"
              class="shadow-lg w-full"
              @click="manualResume"
            >Add your information manually</b-button>
          </div>
        </div>
      </div>
    </b-modal>

    <b-card
      v-if="accountInfo"
    >
      <div
        class="tabs-container"
      >
        <b-tabs
          id="profile"
          v-model="activeTab"
          lazy
          pills
          class="tab-action-btn-fill-container"
          justified
        >
          <b-tab
            title="Account"
            icon-pack="feather"
            icon="icon-user"
            @click="onTab"
          >
            <template
              #title
              size="18"
            >
              <feather-icon
                icon="UserIcon"
                size="18"
              />
              <span>Account</span>
            </template>
            <div class="tab-text">
              <div id="account">
                <b-row class="fill-missing">
                  <div v-if="false" class="vx-col w-100">
                    <div
                      class="d-flex items-start"
                      :class="{'justify-content-center flex-row flex-wrap': (isMobile || isTablet)}"
                    >

                      <div
                        v-if="profileType"
                        :class="{'ml-3': !(isMobile || isTablet)}"
                      >
                        <img
                          ref="profileImage"
                          :src="
                            accountInfo.avatar === 'default' || accountInfo.avatar === null
                              ? require('@/assets/images/placeholder/no-image.jpg')
                              : accountInfo.avatar
                          "
                          style="height: 200px;"
                          class="w-64 ml-1 mt-2"
                        >
                      </div>

                      <div
                        v-else
                        :class="{'ml-3': !(isMobile || isTablet)}"
                      >
                        <img
                          ref="profileImage"
                          :src="
                              require('@/assets/images/placeholder/no-image.jpg')
                          "
                          style="height: 200px;"
                          class="w-64 m1-1 mt-1"
                        >
                      </div>

                      <!-- <b-avatar :src="data.avatar" size="80px" class="mr-4" /> -->
                      <div
                        class="mx-2 w-100"
                        :class="{'mx-3': (isMobile || isTablet)}"
                      >
                        <p
                          v-if="profileType"
                          class="text-lg font-weight-bolder mt-1"
                        >
                          {{ accountInfo.fullname }}
                        </p>
                        <p
                          v-else
                          class="text-lg font-weight-bolder mt-1"
                        >
                          {{ companyInfo.official_name }}
                        </p>
                        <div class="flex flex-wrap xl:w-1/2">
                          <!-- <div class="vx-col w-full">
                            <input
                              ref="uploadImgInput"
                              type="file"
                              class="hidden"
                              accept="image/*"
                              @change="updateCurrImg"
                            >

                            <b-button
                              class="mt-1 mr-2 w-64 shadow-lg"
                              :class="{'mr-0 w-100': (isMobile || isTablet)}"
                              variant="primary"
                              @click="$refs.uploadImgInput.click()"
                            >Upload new image</b-button>
                          </div> -->
                          <div
														v-if="false"
                            style="width: 250px;height: 50px;"
                            class="mb-2"
                          >
                            <file-pond
                              v-if="$route.name !== 'edit-corporate'"
                              ref="pond-user"
                              name="profile-pic-upload"
                              label-idle="Upload a new image."
                              accepted-file-types="image/jpeg, image/png"
                              :server="profileServer"
                              :files="profileFiles"
                              credits="false"
                              @init="handleProfileImagePondInit"
                            />
                            <file-pond
                              v-else
                              ref="pond-company"
                              name="company-profile-pic-upload"
                              label-idle="Upload a new image."
                              accepted-file-types="image/jpeg, image/png"
                              :server="companyProfileServer"
                              :files="companyProfileFiles"
                              credits="false"
                              @init="handleCompanyProfileImagePondInit"
                            />
                          </div>

                          <b-row
                            v-if="$store.state.auth.ActiveUser.user_group.includes(this.$UserGroups.SUPERUSER) && $route.name === 'edit-corporate'"
                            id="upload-ownership-documents"
                            class="mt-2"
                            style="width: 800px;height: 50px;"
                          >
                            <b-col cols="4">
                              <file-pond

                                ref="pond-company-document"
                                name="ownership-document-upload"
                                label-idle="Upload your ownership document."
                                :server="companyServer"
                                :files="companyFiles"
                                credits="false"
                                style="height: 60px!important;width: 250px;"

                                @init="handleCompanyFilePondInit"
                              />
                            </b-col>
                            <b-col cols="4">
                              <b-button
                                v-if="companyInfo.ownership_document_file && !$isEmpty(companyInfo.ownership_document_file)"
                                id="#button-with-loading"
                                class="mr-2 w-full shadow-lg"
                                :class="{'mr-0 w-100': (isMobile || isTablet)}"
                                variant="primary"
                                style="height: 65px;width: 250px;"
                                @click="viewUploadedOwnershipDocument"
                              >View Ownership Document</b-button>
                            </b-col>
                          </b-row>

                          <!-- <div
                            v-if="normal"
                            class="mt-2"
                            style="width: 800px;"
                          >

                            <file-pond
                              id="upload-resume"
                              ref="pond-resume"
                              name="resume-upload"
                              label-idle="Upload a new resume file."
                              accepted-file-types="application/pdf, .doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                              style="height: 60px!important;width: 250px;"
                              :server="resumeServer"
                              :files="resumeFiles"
                              credits="false"
                              @init="handleResumePondInit"
                            />

                            <div class="d-flex flex-wrap w-100">
                              <b-button
                                v-if="accountInfo.resume_file && normal == true"
                                id="#button-with-loading"
                                class="mt-1 shadow-lg px-5"
                                :class="{'w-100': (isMobile || isTablet), 'mr-2': !(isMobile || isTablet)}"
                                variant="primary"
                                @click="viewUploadedResume"
                              >View your resume</b-button>
                            </div>
                          </div> -->
                        </div>

                        <div
                          v-if="false"
                          class=""
                        >
                          <b-alert
                            v-if="accountInfo.resume_file && normal == true"
                            class="mb-1 mt-1 p-1"
                            variant="warning"
                            icon-pack="feather"
                            icon="icon-info"
                            show
                          >You have an existing resume in the system. Click the
                            button above to update.
                          </b-alert>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-row>

                <b-row
                  :class="{'mx-0 mt-1': isMobile, 'mx-2': !isMobile}"
                  cols="12"
                >
                  <b-col
                    v-if="profileType"
                    cols="12"
                    md="6"
                    class="mt-1"
                  >
                    <label for="Full Name">Full Name</label>
                    <b-form-input
                      id="Full Name"
                      v-model="accountInfo.fullname"
                      debounce="5000"
                      :state="!checkFullName(accountInfo.fullname)[0]"
                      placeholder="Full Name"
                    />
                    <b-form-invalid-feedback
                      tooltip
                    >
                      {{ checkFullName(accountInfo.fullname)[1] }}
                    </b-form-invalid-feedback>

                  </b-col>

                  <b-col
                    v-if="profileType"
                    cols="12"
                    md="6"
                    class="mt-1"
                  >
                    <label for="Email">Email Address</label>
                    <b-form-input
                      id="Email"
                      v-model="accountInfo.email_address"
                      type="email"
                      disabled
                      :state="accountInfo.email_address.length > 4 &&
                        accountInfo.email_address.includes('@') &&
                        accountInfo.email_address.includes('.')
                        ? true
                        : false"
                      placeholder="Email"
                    />
                    <b-form-invalid-feedback
                      class="ml-1"
                      tooltip
                    >
                      A valid email address is required
                    </b-form-invalid-feedback>

                  </b-col>

                  <!-- <b-col
										v-if="profileType"
                    cols="12"
                    md="6"
                    class="mt-1"
                  >
                    <label for="Contact Number">Contact Number</label>
                    <b-form-input
                      id="Contact Number"
                      v-model="accountInfo.phone_number"
                      :state="accountInfo.phone_number.length === 8 ? true : false"
                      onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                      maxlength="8"
                      placeholder="Contact Number"
                    />
                    <b-form-invalid-feedback
                      class="ml-1"
                      tooltip
                    >
                      A valid contact number is required
                    </b-form-invalid-feedback>
                  </b-col> -->

                  <b-col
                    v-if="!normal"
                    cols="12"
                    md="6"
                    class="mt-1"
                  >
                    <label for="Company Name">Company Name</label>
                    <b-form-input
                      v-if="companyInfo.company_name && companyInfo.company_name.length > 2"
                      id="Company Name"
                      v-model="companyInfo.company_name"
                      placeholder="Company Name"
                      disabled
                    />

                    <b-form-input
                      v-else
                      id="Company Name"
                      v-model="companyInfo.company_name"
                      class="w-full"
                      disabled
                      placeholder="Company Name"
                    />
                  </b-col>

                  <b-col
                    v-if="!profileType"
                    cols="12"
                    md="6"
                    class="mt-1"
                  >
                    <label for="UEN NO">UEN NO</label>

                    <b-form-input
                      id="UEN NO"
                      v-model="companyInfo.uen_no"
                      class="w-full"
                      placeholder="UEN NO"
                      disabled
                    />
                  </b-col>

                  <b-col
                    v-if="false"
                    cols="12"
                    md="6"
                    class="mt-1"
                  >
                    <label for="Telegram ID">Telegram ID</label>
                    <b-form-input
                      id="Telegram ID"
                      v-model="accountInfo.telegram_id"
                      class="w-full"
                      placeholder="Telegram ID"
                      disabled
                    />
                  </b-col>
                  <b-col
                    v-if="!normal && !profileType"
                    cols="12"
                    md="12"
                    class="mt-1"
                  >

                    <label for="Company Summary">Company Summary</label>
                    <b-form-textarea
                      id="Company Summary"
                      v-model="companyInfo.company_summary"
                      rows="6"
                      class="w-full"
                    />
                  </b-col>

                    <!-- v-if="!normal && !profileType" -->
                  <b-col
                    v-if="false"
                    cols="12"
                    md="6"
                    class="mt-1"
                  >
                    <h6>
                      Agency License Number
                    </h6>
                    <b-form-input
                      v-model="companyInfo.licence_number"
                      class="w-full"
                      placeholder="Agency Licence Number"
                    />
                  </b-col>
                  <b-col
                    v-if="normal"
                    cols="12"
                    md="6"
                    class="mt-1"
                  >

                    <label for="Gender">Gender</label>
                    <v-select
                      id="Gender"
                      v-model="accountInfo.gender"
                      :state="
                        accountInfo.gender !== 'Not Set' &&
                          accountInfo.gender !== ''
                          ? true
                          : false
                      "
                      danger-text="Please select your gender(required)"
                      val-icon-danger="icon-x"
                      val-icon-pack="feather"
                      class="w-full"
                      :options="allGenders"
                      :reduce="value => value.value"
                      label="value"
                    />
                  </b-col>

                  <b-col
                    v-if="normal"
                    cols="12"
                    md="6"
                    class="mt-1 w-full md:w-1/2"
                  >
                    <label class="b-form-input--label">Highest Qualification</label>
                    <v-select
                      v-model="accountInfo.highest_qualification"
                      :danger="
                        accountInfo.highest_qualification !== 'Not Set' &&
                          accountInfo.highest_qualification !== ''
                          ? false
                          : true
                      "
                      danger-text="Please select your highest qualification(required)"
                      val-icon-danger="icon-x"
                      val-icon-pack="feather"
                      class="w-full"
                      :options="highestQualifications"
                      label="value"
                      :reduce="value => value.value"
                    />
                  </b-col>

                  <!-- <b-col
                    v-if="normal"
                    cols="12"
                    md="6"
                    class="mt-1 w-full md:w-1/2"
                  >
                    <label class="b-form-input--label">Date Of Birth</label>
                    <flat-pickr
                      v-model="accountInfo.date_of_birth"
                      class="form-control"
                      :config="dobConfig"
                      placeholder="Date of birth"
                    />
                  </b-col> -->

                  <b-col
                    v-if="normal"
                    cols="12"
                    md="6"
                    class="mt-1 w-full md:w-1/2"
                  >
                    <label class="b-form-input--label">Employment Status</label>
                    <v-select
                      v-model="accountInfo.employment_status"
                      :danger="
                        accountInfo.employment_status !== 'Not Set' &&
                          accountInfo.employment_status !== ''
                          ? false
                          : true
                      "
                      danger-text="Please select your employment status(required)"
                      val-icon-danger="icon-x"
                      val-icon-pack="feather"
                      class="w-full"
                      :options="employmentStatus"
											:searchable="false"
                      label="value"
                      :reduce="value => value.value"
                    />
                  </b-col>

                  <!-- <b-col
                    v-if="normal"
                    cols="12"
                    md="6"
                    class="mt-1 w-full"
                  >
                    <label class="b-form-input--label">Citizenship</label>
                    <v-select
                      v-model="accountInfo.citizenship"
                      :danger="
                        accountInfo.citizenship !== 'Not Set' &&
                          accountInfo.citizenship !== ''
                          ? false
                          : true
                      "
                      danger-text="Please select your citizenship(required)"
                      val-icon-danger="icon-x"
                      val-icon-pack="feather"
                      class="w-full"
                      :options="citizenship"
                      label="value"
                      :reduce="value => value.value"
                    />

                    <v-select
                      v-if="accountInfo.citizenship === 'Others'"
                      v-model="accountInfo.citizenship"
                      danger-text="Please select your citizenship(required)"
                      val-icon-danger="icon-x"
                      val-icon-pack="feather"
                      class="w-full mt-1"
                      :options="allCitizenships"
                      label="value"
                      :reduce="value => value.value"
                    />
                  </b-col> -->

                  <!-- <b-col
                    v-if="normal"
                    cols="12"
                    md="6"
                    class="vx-col mt-1 w-100 md:w-1/2"
                  >
                    <label
                      class="b-form-input--label"
                    >Preferred Work Location(You can select multiple locations)</label>
                    <v-select
                      v-model="accountInfo.preferred_location"
                      :danger="
                        accountInfo.preferred_location &&
                          accountInfo.preferred_location !== 'Not Set' &&
                          accountInfo.preferred_location !== ''
                          ? false
                          : true
                      "
                      danger-text="Please select your preffered work location(required)"
                      val-icon-danger="icon-x"
                      val-icon-pack="feather"
                      class="w-full"
                      multiple
                      :options="prefWorkLocation"
                      :reduce="value => value.value"
                      label="value"
                    />
                  </b-col> -->

                  <b-col
                    v-if="normal"
                    cols="12"
                    md="6"
                    class="mt-1 w-full md:w-1/2"
                  >
                    <label for="Preferred Job Position">Preferred Job Position &nbsp;</label>

                    <feather-icon
                      v-b-tooltip.hover.html="' Principal Designer, Lead Designer and Designer roles include job roles such as Architect, Landscape Architect/Landscape Designer, Interior Designer, Fashion Designer, Product Designer, Furniture Designer, Graphic Designer and Interaction Designer. <br /><br /> Lead Visualiser/Lead Storyteller and Visualiser/Storyteller roles include job roles such as Renderer, Graphic Designer, Multimedia Artist, Commercial Artist, Photographer, Videographer, 2D/3D Visualiser, Creative Visualiser and Data Visualiser.  '"
                      size="18"
                      class="text-danger"
                      icon="InfoIcon"
                      @click="verifyContact('email')"
                    />

                    <!-- <v-select
                      id="Preferred Job Position"
                      v-model="accountInfo.preferred_position"
                      class="w-full"
                      taggable
                      multiple
                      no-drop
                      :map-keydown="selectHandlers"
                      :options="preferred_position_options"
                      :create-option="option => option.toLowerCase()"
                      :danger="
                        accountInfo.preferred_position &&
                          accountInfo.preferred_position !== 'Not Set' &&
                          accountInfo.preferred_position !== ''
                          ? false
                          : true
                      "
                      danger-text="Please select your preffered job position(required)"
                    /> -->

                    <!-- <v-select
                      v-model="accountInfo.preferred_position"
                      val-icon-danger="icon-x"
                      val-icon-pack="feather"
                      class="w-full"
                      multiple
                      :options="preferred_position_options"
											:searchable="false"
                      label="value"
                      :reduce="(value) => value.value"
                    /> -->

                    <v-select
                      v-model="accountInfo.preferred_position"
                      class="w-full"
                      multiple
                      :danger="
                        accountInfo.preferred_position ? false : true
                      "
                      danger-text="Please select your preffered job position(required)"
                      val-icon-danger="icon-x"
                      val-icon-pack="feather"
                      :options="preferred_position_options"
                      label="value"
                      :reduce="value => value.value"
                    />
										<small v-if="false" style="font-size: .7rem; line-height: 150%;">
											<div style="line-height: 120%;">
												Principal Designer, Lead Designer and Designer roles include job roles such as Architect, Landscape Architect/Landscape Designer, Interior Designer, Fashion Designer, Product Designer, Furniture Designer, Graphic Designer and Interaction Designer.
											</div>
											<br/>
											<div style="line-height: 120%;">
												Lead Visualiser/Lead Storyteller and Visualiser/Storyteller roles include job roles such as Renderer, Graphic Designer, Multimedia Artist, Commercial Artist, Photographer, Videographer, 2D/3D Visualiser, Creative Visualiser and Data Visualiser.
											</div>
										</small>

                    <!-- <b-form-input
                      id="Preferred Job Position"
                      v-model="accountInfo.preferred_position"
                      class="w-full"
                      placeholder="Preferred Job Position (Separate multiple positions with a comma)"
                      :danger="
                        accountInfo.preferred_position &&
                          accountInfo.preferred_position !== 'Not Set' &&
                          accountInfo.preferred_position !== ''
                          ? false
                          : true
                      "
                      danger-text="Please select your preffered job position(required)"
                      val-icon-danger="icon-x"
                      val-icon-pack="feather"
                    /> -->
                  </b-col>

                  <b-col
                    v-if="normal"
                    cols="12"
                    md="6"
                    class="vx-col mt-1 w-full md:w-1/2"
                  >
                    <label
                      class="b-form-input--label"
                    >Preferred Industry</label>
                    <v-select
                      v-model="accountInfo.preferred_industry"
                      class="w-full"
                      multiple
                      :danger="
                        accountInfo.preferred_industry ? false : true
                      "
                      danger-text="Please select your preffered job industry(required)"
                      val-icon-danger="icon-x"
                      val-icon-pack="feather"
                      :options="prefIndustry"
                      label="value"
                      :reduce="value => value.value"
                    />
                  </b-col>

                  <!-- <b-col
                    v-if="normal"
                    cols="12"
                    md="6"
                    class="vx-col mt-1 w-full md:w-1/2"
                  >
                    <label for="Residential Postal">Residential Postal</label>
                    <b-form-input
                      id="Residential Postal"
                      v-model="accountInfo.postal_code"
                      class="w-full"
                      placeholder="Residential Postal"
                      :state="
                        !$isEmpty(accountInfo.postal_code)
                          && accountInfo.postal_code !== 'Not Set'
                          && accountInfo.postal_code !== ''
                          ? true
                          : false
                      "
                      danger-text="A valid residential postal is required"
                      val-icon-danger="icon-x"
                      val-icon-pack="feather"
                    />
                    <b-form-invalid-feedback
                      tooltip
                    >
                      A valid residential postal is required
                    </b-form-invalid-feedback>

                  </b-col> -->

                  <!-- <b-col
                    v-if="normal"
                    cols="12"
                    md="6"
                    class="mt-1 w-full md:w-1/2"
                  >
                    <label class="b-form-input--label">Notice Period</label>
                    <div class="d-flex justify-content-between w-100">

                      <b-form-input
                        v-model="accountInfo.availability_number"
                        class="w-50 mx-1"
                        type="text"
                        onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                        :state="
                          accountInfo.availability_number ? true : false
                        "
                        danger-text="Please select how many days, weeks, months when you are available to start the job"
                        val-icon-danger="icon-x"
                        val-icon-pack="feather"
                      />

                      <v-select
                        v-model="accountInfo.availability_period"
                        :danger="
                          accountInfo.availability_period !== 'Not Set' &&
                            accountInfo.availability_period !== ''
                            ? false
                            : true
                        "
                        danger-text="Please select the period when you are available to start the job"
                        val-icon-danger="icon-x"
                        val-icon-pack="feather"
                        class="w-50 mx-1"
                        :options="all_availability"
                        label="value"
                        :reduce="value => value.value"
                      />

                    </div>
                  </b-col> -->
                </b-row>

                <div
                  class="d-flex flex-row-reverse my-2"
                >
                  <b-button
                    class="mx-1 view-profile"
                    variant="warning"
                    @click="gotoView"
                  >View Profile</b-button>

                  <b-button
                    variant="primary"
                    class="mx-1 click-save"
                    @click="saveProfile(false)"
                  >Save Profile</b-button>
                </div>
              </div>
            </div>
          </b-tab>

            <!-- v-if="normal" -->
          <b-tab
            v-if="false"
            :class="normal === true ? '': 'invisible'"
            @click="onTab"
          >
            <template
              #title
              size="18"
            >
              <feather-icon
                id="view-employment"
                icon="LayersIcon"
                size="18"
              />
              <span>Employment</span>
            </template>
            <div class="tab-text">
              <div
                id="employment"
                class="w-full"
              >
                <div class="d-flex flex-row-reverse my-2">
                  <b-button
                    variant="warning"
                    class="mx-2 add-employment"
                    @click="createNewEmployment"
                  >Add new employment history</b-button>
                </div>
                <b-row
                  v-for="(item, index) in employmentInfo"
                  :key="index"
                  cols="10"
                >
                  <b-card
                    border-variant="dark"
                    bg-variant="transparent"
                    class="shadow-none mx-auto w-75 p-md-2"
                  >
                    <b-row class="m-0 p-0 mx-md-2 w-100">
                      <b-col
                        cols="12"
                        md="6"
                        class="w-100 md:w-1/2 mb-md-2 mt-1"
                      >
                        <label class="b-form-input--label">Position title</label>
                        <b-form-input
                          v-model="item.position"
                          class="w-full"
                          placeholder="Position title"
                        />
                      </b-col>
                      <b-col
                        cols="12"
                        md="6"
                        class="w-100 md:w-1/2 mb-md-2 mt-1"
                      >
                        <label class="b-form-input--label">Company Name</label>
                        <b-form-input
                          v-model="item.company_name"
                          class="w-full"
                          placeholder="Company Name"
                        />
                      </b-col>
                      <b-col
                        cols="12"
                        md="6"
                        class="w-100 md:w-1/2 mb-md-2 mt-1"
                      >
                        <label class="b-form-input--label">Start Date</label>
                        <div>
                          <flat-pickr
                            v-model="item.start_date"
                            class="form-control"
                            :config="configdateTimePicker"
                            placeholder="Start Date"
                          />
                        </div>
                      </b-col>
                      <b-col
                        cols="12"
                        md="6"
                        class="w-100 md:w-1/2 mb-md-2 mt-1"
                      >
                        <label class="b-form-input--label">End Date</label>
                        <flat-pickr
                          v-model="item.end_date"
                          class="form-control"
                          :config="configdateTimePicker"
                          placeholder="End Date"
                        />
                      </b-col>

                      <b-col
                        cols="12"
                        class="w-100 md:w-1/2 mb-md-2 mt-1"
                      >
                        <label class="b-form-input--label">Role Description</label>
                        <b-form-textarea
                          v-model="item.description"
                          placeholder=""
                          rows="5"
                        />
                      </b-col>
                    </b-row>

                    <div class="d-flex flex-row-reverse my-2">
                      <b-button
                        variant="danger"
                        class="mx-2"
                        @click="deleteEmployment(item.id, index)"
                      >Delete</b-button>
                    </div>
                  </b-card>
                </b-row>

                <div class="d-flex flex-row-reverse my-10">
                  <b-button
                    variant="primary"
                    class="mx-2 view-profile"
                    @click="gotoView"
                  >View Profile</b-button>

                  <b-button
                    variant="warning"
                    class="mx-2 click-save"
                    @click="saveProfile(false)"
                  >Save Profile</b-button>
                </div>
              </div>
            </div>
          </b-tab>

          <b-tab
            v-if="normal"
            :class="normal === true ? '': 'invisible'"
            title="Education"
            icon-pack="feather"
            icon="icon-book"
            @click="onTab"
          >
            <template
              #title
              size="18"
            >
              <feather-icon
                id="view-education"
                icon="BookIcon"
                size="18"
              />
              <span>Education</span>
            </template>
            <div class="tab-text">
              <div class="d-flex justify-content-center">
                <b-col
                  v-if="normal"
                  cols="12"
                  md="9"
                  class="mt-1 w-full md:w-1/2"
                >
                  <div class="d-flex flwx-row w-100 align-items-center">
                    <label
                      class=""
                      style="min-width: 25ch;"
                    >Highest Qualification</label>
                    <v-select
                      v-model="accountInfo.highest_qualification"
                      :danger="
                        accountInfo.highest_qualification !== 'Not Set' &&
                          accountInfo.highest_qualification !== ''
                          ? false
                          : true
                      "
                      danger-text="Please select your highest qualification(required)"
                      val-icon-danger="icon-x"
                      val-icon-pack="feather"
                      class="w-full w-100"
                      :options="highestQualifications"
                      label="value"
                      :reduce="value => value.value"
                    />
                  </div>
                </b-col>
              </div>

              <div id="education" class="pt-2">
                <div class="d-flex flex-row-reverse justify-content-center my-2">
                  <b-button
                    id="add-education"
                    variant="warning"
                    class="mx-2"
                    @click="createNewEducation"
                  >Add new education history</b-button>
                </div>
                <b-row
                  v-for="(item, index) in educationInfo"
                  :key="index"
                  class="vx-row mx-md-2"
                >
                  <b-card
                    border-variant="dark"
                    bg-variant="transparent"
                    class="shadow-none mx-auto w-75 p-md-2"
                  >
                    <b-row class="vx-row m-0 p-0 mx-md-2 w-100">
                      <b-col
                        cols="12"
                        md="6"
                        class="mb-md-2 mt-1"
                      >
                        <label class="b-form-input--label">School</label>
                        <!-- <b-form-input
                          v-model="item.school_name"
                          class="w-full"
                          placeholder="School"
                        /> -->
												<v-select
                          v-model="item.school_name"
													danger-text="Please select your school"
													class=""
													:options="optionsSchoolName"
													label="text"
                          :reduce="(value) => value.value"
												/>
                        <b-form-input
													v-if="item.school_name === 'Other'"
                          v-model="item.school_name_other"
                          class="w-full mt-1"
                          placeholder="Input the name of the school"
                        />
                      </b-col>
                      <b-col
                        cols="12"
                        md="6"
                        class="mb-md-2 mt-1"
                      >
                        <label class="b-form-input--label">Field of Study</label>
                        <v-select
                          id="selectFieldOfStudy"
                          v-model="item.field_of_study"
                          danger-text="Please select your Field of study"
                          val-icon-danger="icon-x"
                          val-icon-pack="feather"
                          class="w-full"
                          :options="optionsFieldOfStudy"
                          placeholder="Field of study"
                          :reduce="value => value.value"
                          label="text"
                        />
                      </b-col>
                      <b-col
                        cols="12"
                        md="6"
                        class="mb-md-2 mt-1"
                      >
                        <label class="b-form-input--label">Start Date</label>
                        <div>
                          <flat-pickr
                            v-model="item.start_date"
                            class="form-control"
                            :config="configdateTimePicker"
                            placeholder="Start Date"
                          />
                        </div>
                      </b-col>
                      <b-col
                        cols="12"
                        md="6"
                        class="mb-md-2 mt-1"
                      >
                        <label class="b-form-input--label">End Date</label>
                        <div>
                          <flat-pickr
                            v-model="item.end_date"
                            class="form-control"
                            :config="configdateTimePicker"
                            placeholder="End Date"
                          />
                        </div>
                      </b-col>

                      <b-col
                        cols="12"
                        class="w-full mt-1"
                      >
                        <label class="b-form-input--label">Description</label>
                        <b-form-textarea
                          v-model="item.description"
                          placeholder=""
                          rows="2"
                        />
                      </b-col>

											<!-- <b-col
												cols="12"
												class="w-full mt-1"
											>
												<label class="b-form-input--label">Description</label>
												<b-form-textarea
													v-model="item.description"
													placeholder=""
													rows="2"
												/>
											</b-col> -->
                    </b-row>

                    <div class="d-flex flex-row-reverse my-2">
                      <b-button
                        variant="danger"
                        class="mx-2"
                        @click="deleteEducation(item.id, index)"
                      >Delete</b-button>
                    </div>
                  </b-card>
                </b-row>

                <div class="d-flex flex-row-reverse my-2">
                  <b-button
                    variant="primary"
                    class="mx-2 view-profile"
                    @click="gotoView"
                  >View Profile</b-button>
                  <b-button
                    variant="warning"
                    class="mx-2 click-save"
                    @click="saveProfile(false)"
                  >Save Profile</b-button>
                </div>
              </div>
            </div>
          </b-tab>

          <b-tab
            v-if="profileType"
            title="Security"
            icon-pack="feather"
            icon="icon-lock"
          >
            <template
              #title
              size="18"
            >
              <feather-icon
                icon="LockIcon"
                size="18"
              />
              <span>Security</span>
            </template>
            <div class="tab-text">
              <div id="security">
                <b-row class="my-1 mx-3">
                  <!-- <div
                    v-if="
                      $store.state.auth.ActiveUser.account_type === $AccountTypes.APPLICANT ||
                        $store.state.auth.ActiveUser.user_group.includes($UserGroups.SUPERUSER)
                    "
                    class=""
                  > -->
                  <div
                    v-if="
                      accountInfo.account_type === $AccountTypes.APPLICANT ||
                        accountInfo.user_group.includes($UserGroups.SUPERUSER)
                    "
                    class=""
                  >
                    <b-form-checkbox
                      v-model="accountInfo.two_factor_active"
                      class="mt-2 mx-1"
                    >Turn on two-factor authentication</b-form-checkbox>
                  </div>
                </b-row>
                <div class="container my-1 mx-2">
                  <hr>

                  <b-row class="mt-2 mx-1">
                    <h6 class="font-weight-bolder">
                      Change your password
                    </h6>
                  </b-row>

                  <b-row class="mt-1 mx-1">
                    <h6 class="text-danger ">
                      Your current password will expire
                      {{ accountInfo.days_left }}.
                    </h6>
                  </b-row>
                  <b-row
                    cols="12"
                    md="6"
                    class="mx-1"
                  >
                    <!-- password -->
                    <b-form-group
                      class="w-50"
                      label-for="old-password"
                      label="Old Password"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Old Password"
                        vid="old-password"
                        rules="required"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                        >
                          <b-form-input
                            id="old-password"
                            v-model="oldPassword"
                            class="form-control-merge"
                            :type="passwordFieldType"
                            :state="errors.length > 0 ? false : null"
                            name="old-password"
                            placeholder="············"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              :icon="passwordToggleIcon"
                              class="cursor-pointer"
                              @click="togglePasswordVisibility"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                  </b-row>
                  <b-row
                    cols="12"
                    md="5"
                    class="mx-1"
                  >
                    <b-form-group
                      label-for="settings-password"
                      label="Password"
                      class="w-50"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Password"
                        vid="password"
                        rules="required|password"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                        >
                          <b-form-input
                            id="settings-password"
                            v-model="userPassword"
                            class="form-control-merge"
                            :type="passwordFieldType"
                            :state="errors.length > 0 ? false : null"
                            name="settings-password"
                            placeholder="············"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              :icon="passwordToggleIcon"
                              class="cursor-pointer"
                              @click="togglePasswordVisibility"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-row>
                  <b-row
                    cols="12"
                    md="5"
                    class="mx-1"
                  >
                    <!-- confirm password -->
                    <b-form-group
                      label-for="settings-confirm-password"
                      label="Confirm Password"
                      class="w-50"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Confirm Password"
                        rules="required|confirmed:password"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                        >
                          <b-form-input
                            id="settings-confirm-password"
                            v-model="confirmPassword"
                            class="form-control-merge"
                            :type="passwordFieldType"
                            :state="errors.length > 0 ? false : null"
                            name="settings-confirm-password"
                            placeholder="············"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              :icon="passwordToggleIcon"
                              class="cursor-pointer"
                              @click="togglePasswordVisibility"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-row>
                </div>

                <b-row class="my-1 w-100 ml-2">
                  <b-button
                    :disabled="!validateForm"
                    class="ml-2"
                    variant="warning"
                    @click="saveProfile(false, true)"
                  >Change Password</b-button>
                </b-row>

                <div class="d-flex flex-row-reverse my-1">
                  <b-button
                    variant="primary"
                    class="mx-1 view-profile"
                    @click="gotoView"
                  >View Profile</b-button>
                  <b-button
                    variant="warning"
                    class="mx-1 click-save"
                    @click="saveProfile(false)"
                  >Save Profile</b-button>
                </div>

              </div>
            </div>
          </b-tab>

            <!-- v-if="profileType" -->
          <b-tab
            v-if="false"
            label="Connections"
            icon-pack="feather"
            icon="icon-link-2"
          >
            <template
              #title
              size="18"
            >
              <feather-icon
                icon="Link2Icon"
                size="18"
              />
              <span>Connections</span>
            </template>
            <div class="tab-text">
              <div id="connection">
                <div class="vx-row mt-1 mx-2">
                  <b-button
                    v-if="has_logged_telegram"
                    icon-pack="feather"
                    icon="icon-send"
                    class="mr-3 mb-2"
                    variant="#54A8EB"
                    @click="telegramLogout"
                  >
                    Log out of telegram
                  </b-button>

                  <vue-telegram-login
                    v-else
                    mode="callback"
                    request-access="write"
                    :userpic="false"
                    :telegram-login="bot_username"
                    @callback="afterTelegram"
                  />
                </div>
                <hr>
                <div class="vx-row mt-2 mx-2">
                  <div
                    v-if="
                      this.$store.state.auth.ActiveUser.account_type === this.$AccountTypes.APPLICANT
                    "
                    class=""
                  >
                    <b-form-checkbox
                      v-model="e2i_recommendation"
                      class="mt-2"
                    >Allow e2i to send you job recommendations</b-form-checkbox>
                  </div>
                </div>
                <hr>
                <div class="d-flex flex-row-reverse my-2">
                  <b-button
                    class="view-profile"
                    variant="primary"
                    @click="gotoView"
                  >View Profile</b-button>
                  <b-button
                    variant="warning"
                    class="mx-2 click-save"
                    @click="saveProfile(false)"
                  >Save Profile</b-button>
                </div>
              </div>
            </div>
          </b-tab>

          <!-- <b-tab
            v-if="
              (this.$store.state.auth.ActiveUser.account_type === this.$AccountTypes.EDUCATION_INSTITUTION ||
                this.$store.state.auth.ActiveUser.account_type === this.$AccountTypes.AGENCY) && !profileType

            "
          > -->
          <b-tab
            v-if="false"
          >
            <template
              #title
              size="18"
            >
              <feather-icon
                icon="SmartphoneIcon"
                size="18"
              />
              <span>Point of Contact</span>
            </template>
            <div class="tab-text">
              <div id="poc">
                <div
                  v-if="
                    this.$store.state.auth.ActiveUser.account_type === this.$AccountTypes.EDUCATION_INSTITUTION ||
                      this.$store.state.auth.ActiveUser.account_type === this.$AccountTypes.AGENCY
                  "
                  class="vx-row mx-1 mb-8"
                >
                  <b-card
                    no-shadow
                    card-border
                  >
                    <div class="header mb-2">
                      <h4>Company Settings</h4>
                    </div>

                    <div class="mt-2 mb-1 underline">
                      <h6 class="font-weight-bolder">
                        1. Point of contact
                      </h6>
                    </div>
                    <div class="mx-2">
                      <b-row>
                        <b-col
                          cols="12"
                          md="6"
                          class="d-flex flex-wrap"
                        >
                          <h6>
                            Full Name
                          </h6>
                          <b-form-input
                            v-model="poc.fullname"
                            class="w-full"
                            placeholder="Contact Full Name"
                          />
                        </b-col>

                        <b-col
                          cols="12"
                          md="6"
                          class="d-flex flex-wrap"
                          :class="{'mt-1': isMobile}"
                        >
                          <h6>
                            Phone Number
                          </h6>
                          <!-- <b-form-input
                            type="number"
                            class="w-full"
                            placeholder="Contact Phone Number"
                            v-model="poc.phone_number"
                          /> -->
                          <b-input-group prepend="+65">
                            <b-form-input
                              v-model="poc.phone_number"
                              type="number"
                              placeholder="Contact Phone Number"
                              maxlength="8"
                              :formatter="$limitPhoneNumber"
                            />
                          </b-input-group>
                        </b-col>
                      </b-row>

                      <b-row class="mt-1">
                        <b-col class="d-flex flex-wrap">
                          <h6>
                            Email
                          </h6>
                          <b-form-input
                            v-model="poc.email_address"
                            type="email"
                            class="w-full"
                            placeholder="Contact Email"
                          />
                        </b-col>
                      </b-row>

                      <b-row class="mt-1">
                        <b-col class="d-flex flex-wrap">
                          <h6>
                            Agency License Number
                          </h6>
                          <b-form-input
                            v-model="poc.agency_number"
                            class="w-full"
                            placeholder="Agency Licence Number"
                          />
                        </b-col>
                      </b-row>
                    </div>

                    <div class="mt-2 mb-1 underline">
                      <h6 class="font-weight-bolder">
                        2. Which one of these functions do you do?
                      </h6>
                    </div>
                    <div class="mx-2">
                      <b-row>
                        <b-col class="vx-col md:w-1/4 w-full mb-2">
                          <b-form-checkbox
                            v-model="poc.recruitment"
                          >Placement</b-form-checkbox>
                        </b-col>
                        <b-col class="vx-col md:w-1/4 w-full mb-2">
                          <b-form-checkbox
                            v-model="poc.outsourcing"
                          >Outsourcing</b-form-checkbox>
                        </b-col>
                        <b-col class="vx-col md:w-1/4 w-full mb-2">
                          <b-form-checkbox v-model="poc.payroll">
                            Payroll
                          </b-form-checkbox>
                        </b-col>
                        <b-col class="vx-col md:w-1/4 w-full mb-2">
                          <b-form-checkbox
                            v-model="poc.other_func"
                          >Other functions</b-form-checkbox>
                        </b-col>
                      </b-row>
                      <b-row
                        v-if="poc.other_func"
                        class="vx-row mx-1 mt-1"
                      >
                        <b-form-input
                          v-model="poc.extra_functions"
                          class="w-full"
                          placeholder="Which other functions do you perform? If there are multiple functions, separate it with |"
                        />
                      </b-row>
                    </div>

                    <div class="mt-2 mb-1 underline">
                      <h6 class="font-weight-bolder">
                        3. Which type of jobs do you specialize in?
                      </h6>
                    </div>
                    <div class="mx-2">
                      <b-row>
                        <b-col
                          cols="12"
                          md="6"
                        >
                          <b-form-checkbox
                            v-model="poc.parttime"
                          >Part-time</b-form-checkbox>
                        </b-col>
                        <b-col
                          cols="12"
                          md="6"
                          :class="{'mt-1': isMobile}"
                        >
                          <b-form-checkbox
                            v-model="poc.fulltime"
                          >Full-time</b-form-checkbox>
                        </b-col>
                      </b-row>
                    </div>

                    <div class="mt-2 mb-1 underline">
                      <h6 class="font-weight-bolder">
                        4. How much commission do you charge?
                      </h6>
                    </div>
                    <div class="mx-2">
                      <b-row>
                        <b-col
                          cols="12"
                          class="d-flex flex-wrap"
                        >
                          <h6>
                            Commission
                          </h6>
                          <b-form-input
                            v-model="poc.commission"
                            class="w-full"
                            placeholder="Commission For Example, 3% per annum"
                          />
                        </b-col>
                        <b-col cols="12">
                          <div class="vx-col md:w-1/3 w-full mt-2">
                            <b-form-checkbox
                              v-model="poc.offer_comp"
                            >We offer competitive rates for bulk
                              orders.</b-form-checkbox>
                          </div>
                        </b-col>
                        <b-col
                          v-if="poc.offer_comp"
                        >
                          <b-row class="mt-1">
                            <b-col>
                              <h6>
                                Number of Orders
                              </h6>
                              <b-form-input
                                v-model="poc.num_orders"
                                class="w-full"
                                placeholder="Above how many orders?"
                              />
                            </b-col>
                            <b-col>
                              <h6>
                                Bulk Commission
                              </h6>
                              <b-form-input
                                v-model="poc.bulk_commission"
                                class="w-full"
                                placeholder="Bulk commission For Example, 3% per annum"
                              />
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </div>

                    <div class="mt-2 mb-1 underline">
                      <h6 class="font-weight-bolder">
                        5. Which industries do you serve?
                      </h6>
                    </div>
                    <div class="mx-2">
                      <b-row>
                        <b-col>
                          <b-form-radio
                            v-model="poc.industries"
                            value="all"
                          >All industries</b-form-radio>
                        </b-col>
                        <b-col
                          :class="{'mt-1': isMobile}"
                        >
                          <b-form-radio
                            v-model="poc.industries"
                            value="specific"
                          >Specific industries</b-form-radio>
                        </b-col>

                        <b-col
                          cols="12"
                          class="mt-1"
                        >
                          <div
                            v-if="poc.industries == 'specific'"
                          >
                            <v-select
                              v-model="poc.selected_industries"
                              multiple
                              autocomplete
                              placeholder="Select the specific industries you serve"
                              class="w-full"
                              name="item-category"
                              :options="prefIndustry"
                              label="value"
                              :reduce="value => value.value"
                            />
                          </div>
                        </b-col>
                      </b-row>

                    </div>
                  </b-card>
                </div>

                <div class="d-flex flex-row-reverse my-10">
                  <b-button
                    class="mx-2 view-profile"
                    variant="primary"
                    @click="gotoView"
                  >View Profile</b-button>
                  <b-button
                    variant="warning"
                    class="mx-2 click-save"
                    @click="saveCompany()"
                  >Save Profile</b-button>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
      <v-tour
        v-if="false"
        ref="tourguide"
        name="ProfileTour"
        :steps="steps"
        :callbacks="tourCallbacks"
        :options="{ highlight: false }"
      />
    </b-card>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'

import { optionsSchoolName, optionsFieldOfStudy } from '@/constants/datastore'
import { vueTelegramLogin } from 'vue-telegram-login'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import 'flatpickr/dist/themes/airbnb.css'

import {
  cloneDeep, debounce, difference, orderBy,
} from 'lodash'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, confirmed, password,
} from '@validations'

import { allIndustries, highestQualifications, allCitizenships } from '@/constants/datastore'
import {
  BCard,
  BFormGroup,
  BFormInput,
  BButton,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BModal,
  VBModal,
  BBadge,
  BAvatar,
  BOverlay,
  BFormCheckbox,
  BFormRow,
  BTabs, BTab,
  BFormTextarea,

  BFormValidFeedback,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import { singleUpload } from '@/components/awsS3'

import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import VSelect from 'vue-select'
import { onResizeMixins } from '@/components/mixins/onResizeMixins'

import vueFilePond from 'vue-filepond'
// Import FilePond styles
import 'filepond/dist/filepond.min.css'

// Import FilePond plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,

)
// const dict = {
//   custom: {
//     password: {
//       regex: () => 'Your password must be a minimum of 8 characters and must contain at least one uppercase letter, one number and one symbol.',
//     },
//   },
// }

export default {
  components: {
    FilePond,
    ValidationProvider,
    ValidationObserver,
    VSelect,
    BFormTextarea,
    BTabs,
    BTab,
    BCard,
    BFormGroup,
    BFormInput,
    BFormRow,
    BFormValidFeedback,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BModal,
    VBModal,
    BBadge,
    BAvatar,
    BOverlay,
    BFormCheckbox,
    BFormInvalidFeedback,
    Datepicker,
    vueTelegramLogin,
    flatPickr,
  },
  mixins: [togglePasswordVisibility, onResizeMixins],
  props: {
    accountInfo: { type: Object },
    companyInfo: { type: Object },
    employmentInfo: { type: Array },
    educationInfo: { type: Array },
    poc: { type: Object },
    profileType: { type: Boolean },
  },
  data() {
    return {
      resumeFiles: [],
      resumeServer: {

      },
      companyServer: {

      },
      profileServer: {

      },
      companyProfileServer: {

      },
      companyProfileFiles: null,
      profileFiles: null,
      companyFiles: null,
      notify_resume_parser: false,
      tourCallbacks: {
        onSkip: this.finishTour,
        onFinish: this.finishTour,
        onPreviousStep: this.changeOfTab,
        onNextStep: this.changeOfTab,
      },
      finished_tour: false,
      // steps: [
      //   {
      //     target: '.fill-missing', // We're using document.querySelector() under the hood
      //     content: 'Please fill all fields that have been highlighted below.',
      //     params: {
      //       placement: 'top',
      //     },
      //   },
      //   {
      //     target: '.click-save', // We're using document.querySelector() under the hood
      //     content: 'Click here to save your account details.',
      //   },
      //   {
      //     target: '#upload-resume', // We're using document.querySelector() under the hood
      //     content:
      //       'Click here to upload your resume. We will help you to fill up your education and employment history using the information provided in the resume.',
      //   },
      //   {
      //     target: '#view-employment', // We're using document.querySelector() under the hood
      //     content:
      //       'We have extracted your employment history. Click here to view and edit your employment history.',
      //     params: {
      //       placement: 'top',
      //     },
      //   },
      //   {
      //     target: '.add-employment', // We're using document.querySelector() under the hood
      //     content: 'Click here to add your employment history.',
      //     params: {
      //       placement: 'top',
      //     },
      //   },
      //   {
      //     target: '.click-save', // We're using document.querySelector() under the hood
      //     content: 'Click here to save your employment history.',
      //   },
      //   {
      //     target: '#view-education', // We're using document.querySelector() under the hood
      //     content:
      //       'We have extracted your education history. Click here to view and edit your education history.',
      //     params: {
      //       placement: 'top',
      //     },
      //   },
      //   {
      //     target: '#add-education', // We're using document.querySelector() under the hood
      //     content: 'Click here to add your education history.',
      //     params: {
      //       placement: 'top',
      //     },
      //   },
      //   {
      //     target: '.click-save', // We're using document.querySelector() under the hood
      //     content: 'Click here to save your education details.',
      //   },
      //   {
      //     target: '.view-profile', // We're using document.querySelector() under the hood
      //     content: 'Click here to view a summary of your profile details.',
      //   },
      // ],
      activeTab: 0,
      userPassword: '',
      confirmPassword: '',
      oldPassword: '',
      latestUpdates: {
        employment: false,
        education: false,
        account: false,
        poc: false,
      },

      e2i_recommendation: false,
      pause_watch: {
        employment: false,
        education: false,
        account: false,
      },
      first_run: true,
      dobConfig: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'M j, Y',
        altInput: true,
        dateFormat: 'Y-m-d',
      },
      configdateTimePicker: {
        inline: false,
      },
      has_logged_telegram: false,
      normal: true,
      bot_username: '',
      allGenders: [
        {
          text: 'Male',
          value: 'Male',
        },
        {
          text: 'Female',
          value: 'Female',
        },
        // {
        //   text: 'Others',
        //   value: 'Others',
        // },
      ],
      highestQualifications: [],
      employmentStatus: [
				{
					text: 'Employed',
					value: 'Employed',
				},
        {
          text: 'Self-Employed',
          value: 'Self-Employed',
        },
        {
          text: 'Unemployed',
          value: 'Unemployed',
        },
      ],

      citizenship: [
        {
          text: 'Singaporean',
          value: 'Singaporean',
        },
        {
          text: 'Singaporean PR',
          value: 'Singaporean PR',
        },
        {
          text: 'Others',
          value: 'Others',
        },
      ],

      all_availability: [
        {
          text: 'Days',
          value: 'Days',
        },
        {
          text: 'Weeks',
          value: 'Weeks',
        },
        {
          text: 'Months',
          value: 'Months',
        },
      ],

      prefWorkLocation: [
        {
          text: 'North',
          value: 'North',
        },
        {
          text: 'South',
          value: 'South',
        },
        {
          text: 'Central',
          value: 'Central',
        },
        {
          text: 'East',
          value: 'East',
        },
        {
          text: 'West',
          value: 'West',
        },
      ],

      prefIndustry: [],
      preferred_position_options: [
				{ text: 'Chief Design Officer/Chief Innovation Officer/Chief Experience Officer/Chief Strategy Officer', value: 'Chief Design Officer/Chief Innovation Officer/Chief Experience Officer/Chief Strategy Officer' },
				{ text: 'Head of Design Production', value: 'Head of Design Production' },
				{ text: 'Head of Projects/Head of Products', value: 'Head of Projects/Head of Products' },
				{ text: 'Head of Transformation', value: 'Head of Transformation' },
				{ text: 'Principal Designer', value: 'Principal Designer' },
				{ text: 'Principal Design Researcher', value: 'Principal Design Researcher' },
				{ text: 'Principal Service Designer/Principal Experience Designer', value: 'Principal Service Designer/Principal Experience Designer' },
				{ text: 'Head of Strategy', value: 'Head of Strategy' },
				{ text: 'Creative Technologist', value: 'Creative Technologist' },
				{ text: 'Applications Development Manager', value: 'Applications Development Manager' },
				{ text: 'Design Production Manager', value: 'Design Production Manager' },
				{ text: 'Design Project Manager', value: 'Design Project Manager' },
				{ text: 'Product Manager', value: 'Product Manager' },
				{ text: 'Design Coach/Design Accelerator', value: 'Design Coach/Design Accelerator' },
				{ text: 'Lead Designer', value: 'Lead Designer' },
				{ text: 'Lead Visualiser/Lead Storyteller', value: 'Lead Visualiser/Lead Storyteller' },
				{ text: 'Content Strategist', value: 'Content Strategist' },
				{ text: 'Lead Design Researcher', value: 'Lead Design Researcher' },
				{ text: 'Lead Service Designer/Lead Experience Designer', value: 'Lead Service Designer/Lead Experience Designer' },
				{ text: 'Lead Strategist/Lead Business Designer', value: 'Lead Strategist/Lead Business Designer' },
				{ text: 'Design Facilitator', value: 'Design Facilitator' },
				{ text: 'Designer', value: 'Designer' },
				{ text: 'Visualiser/Storyteller', value: 'Visualiser/Storyteller' },
				{ text: 'Content Writer', value: 'Content Writer' },
				{ text: 'Design Researcher', value: 'Design Researcher' },
				{ text: 'Strategist/Business Designer', value: 'Strategist/Business Designer' },
			],
      optionsFieldOfStudy,
      optionsSchoolName,

      profilePopup: false,
      current_event: '',
      view_ownership_document_popup: false,
      view_resume_popup: false,
      document_type: 'pdf',
      ownership_document_source: '',
      file_source: '',
      required,
      password,
      email,
      confirmed,
      allCitizenships: [],
      isLoading: true,
    }
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    computedEmployment() {
      return cloneDeep(this.employmentInfo)
    },
    computedEducation() {
      return cloneDeep(this.educationInfo)
    },
    validateForm() {
      return (
        this.userPassword !== '' && this.confirmPassword !== ''
      )
    },
  },
  watch: {
    computedEmployment: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      deep: true,
      handler(val, oldVal) {
        const outer_this = this
        if (!this.first_run && !this.latestUpdates.employment) {
          if (!this.$isEmpty(val)) {
            if (val.length === 1) {
              this.latestUpdates.employment = true
              debounce(params => {
                outer_this.updateEmployment('update', val[0].id, 0)
              }, 2000)()
            } else if (val.length > 1) {
              const diff = val.map((item, index) => {
                if (oldVal) {
                  if (oldVal[index]) {
                    const old_values = Object.values(oldVal[index])
                    const new_values = Object.values(item)
                    const output = difference(old_values, new_values)

                    if (output.length > 0) {
                      return index
                    }
                  }
                }
              })
              
              for (const idx of diff) {
                if (idx) {
                  this.latestUpdates.employment = true
                  debounce(() => {
                    outer_this.updateEmployment('update', val[idx].id, idx)
                  }, 2000)()
                }
              }
            }
          }
        } else {
          this.first_run = false
        }
      },
    },

    computedEducation: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      deep: true,
      handler(val, oldVal) {
        const outer_this = this
        if (!this.first_run && !this.latestUpdates.education) {
          if (!this.$isEmpty(val)) {
            if (val.length === 1) {
              this.latestUpdates.education = true
              debounce(params => {
                outer_this.updateEducation('update', val[0].id, 0)
              }, 2000)()
            } else if (val.length > 1) {
              const diff = val.map((item, index) => {
                if (oldVal) {
                  if (oldVal[index]) {
                    const old_values = Object.values(oldVal[index])
                    const new_values = Object.values(item)
                    const output = difference(old_values, new_values)

                    if (output.length > 0) {
                      return index
                    }
                  }
                }
              })

              for (const idx of diff) {
                if (idx) {
                  this.latestUpdates.education = true
                  debounce(() => {
                    outer_this.updateEducation('update', val[idx].id, idx)
                  }, 2000)()
                }
              }
            }
          }
        } else {
          this.first_run = false
        }
      },
    },

    accountInfo: {
      immediate: true,
      deep: true,
      handler(val, oldVal) {
        if (!this.first_run && !this.latestUpdates.account) {
          const outer_this = this
          this.latestUpdates.account = true

          debounce(() => {
            if (outer_this.$route.name !== 'edit-corporate') {
              outer_this.saveProfile()
            } else {
              outer_this.saveProfile(true)
            }
          }, 5000)()
        } else {
          this.first_run = false
        }
      },
    },
    companyInfo: {
      immediate: true,
      deep: true,
      handler(val, oldVal) {
        if (!this.first_run && !this.latestUpdates.account) {
          const outer_this = this
          this.latestUpdates.account = true
          debounce(() => {
            if (outer_this.$route.name === 'edit-corporate') {
              outer_this.saveProfile(true)
            }
          }, 3000)()
        } else {
          this.first_run = false
        }
      },
    },
    poc: {
      immediate: true,
      deep: true,
      handler(val, oldVal) {
        if (!this.first_run && !this.latestUpdates.poc && this.$store.state.auth.ActiveUser.account_type !== this.$AccountTypes.APPLICANT) {
          const outer_this = this
          this.latestUpdates.poc = true
          debounce(() => {
            // outer_this.$set(outer_this.companyInfo, 'licence_number', outer_this.poc.agency_number)
            outer_this.saveCompany()
          }, 2000)()
        } else {
          this.first_run = false
        }
      },
    },

    activeTab(newVal) {
      if (newVal === 1) {
        this.changeOfTab(3)
      } else if (newVal === 2) {
        this.changeOfTab(6)
      }
    },
  },
  mounted() {
    if (this.$store.state.auth.ActiveUser.account_type === this.$AccountTypes.APPLICANT) {
      if (!this.finished_tour) {
        // this.$tours.ProfileTour.start()
      }
    }
  },

  created() {
    this.first_run = true
    const { token } = this.$store.state.auth.ActiveUser
    this.$http.defaults.headers.common.Authorization = `Token ${token}`

    // this.image_url = this.$store.state.userImg;
    const company = this.$store.state.auth.ActiveUser.company_name
    if (company === 'e2i') {
      this.bot_username = 'e2i_Bot'
    } else if (company === 'PersolKelly') {
      this.bot_username = 'PERSOLKELLY_BOT'
    } else if (company === 'IoTalents') {
      this.bot_username = 'IoTalents_Bot'
    } else if (company === 'SteadyLah Network') {
      this.bot_username = 'SteadyLah_Network_Bot'
    } else if (company === 'Julian Grey') {
      this.bot_username = 'julian'
    } else if (company === 'DYNAMIC HUMAN CAPITAL PTE. LTD.') {
      this.bot_username = 'DHC_DEMO_BOT'
    } else if (company === 'FASTCO PTE. LTD.') {
      this.bot_username = 'FastJobs_Bot'
    } else if (company === 'ELITEZ & ASSOCIATES PTE. LTD.') {
      this.bot_username = 'Elitez_Demo_Bot'
    } else if (company === 'ELITEZ PTE. LTD.') {
      this.bot_username = 'Elitez_and_Associates_Demo_Bot'
    } else if (company === 'ADECCO PERSONNEL PTE LTD') {
      this.bot_username = 'Adecco_Personnel_Bot'
    } else {
      this.bot_username = 'JobsBank_Bot'
    }

    const { account_type } = this.$store.state.auth.ActiveUser
    if (account_type === this.$AccountTypes.APPLICANT) {
      this.normal = true
    } else {
      this.normal = false
    }

    this.prefIndustry = orderBy(allIndustries, ['value'], ['asc'])
    this.highestQualifications = orderBy(highestQualifications, ['value'], ['asc'])
    this.allGenders = orderBy(this.allGenders, ['value'], ['asc'])
    this.citizenship = orderBy(this.citizenship, ['value'], ['asc'])
    this.employmentStatus = orderBy(this.employmentStatus, ['value'], ['asc'])

    this.all_availability = orderBy(this.all_availability, ['value'], ['asc'])
    this.prefWorkLocation = orderBy(this.prefWorkLocation, ['value'], ['asc'])

    this.allCitizenships = orderBy(allCitizenships, ['value'], ['asc'])

    if (this.accountInfo.telegram_id !== 0) {
      this.has_logged_telegram = true
    }
    this.getConfigInfo()
  },
  methods: {

    handleResumePondInit() {
      const { id } = this.$store.state.auth.ActiveUser
      const outerThis = this
      this.$refs['pond-resume']._pond.setOptions({
        server: {
          process(fieldName, file, metadata, load, error, progress, abort) {
            const extension = file.name.split('.').pop()

            singleUpload(file, 'user-resume', `${id}.${extension}`, load, error, progress, abort, null, outerThis)
          },
        },
      })
    },

    handleCompanyFilePondInit() {
      const { company_id } = this.$store.state.auth.ActiveUser
      const outerThis = this
      this.$refs['pond-company-document']._pond.setOptions({
        server: {
          process(fieldName, file, metadata, load, error, progress, abort) {
            const extension = file.name.split('.').pop()
            singleUpload(file, 'ownership-documents', `${company_id}.${extension}`, load, error, progress, abort, null, outerThis)
          },
        },
      })
    },
    handleCompanyProfileImagePondInit() {
      const { company_id } = this.$store.state.auth.ActiveUser
      const outerThis = this
      this.$refs['pond-company']._pond.setOptions({
        server: {
          process(fieldName, file, metadata, load, error, progress, abort) {
            const extension = file.name.split('.').pop()
            singleUpload(file, 'company-profile-images', `${company_id}.${extension}`, load, error, progress, abort, null, outerThis)
          },
        },
      })
    },
    handleProfileImagePondInit() {
      const { id } = this.$store.state.auth.ActiveUser
      const outerThis = this
      const imgRef = this.$refs.profileImage
      this.$refs['pond-user']._pond.setOptions({
        server: {
          process(fieldName, file, metadata, load, error, progress, abort) {
            const extension = file.name.split('.').pop()

            singleUpload(file, 'user-profile-images', `${id}.${extension}`, load, error, progress, abort, imgRef, outerThis)
          },
        },
      })

      // FilePond instance methods are available on `this.$refs.pond`
    },
    resumeClosed() {
      this.file_source = null
    },
    ownershipDocumentClosed() {
      this.ownership_document_source = null
    },
    getPresignedUploadUrl(extension, fd, reader, file) {
      this.$http
        .get(`/api/pre-signed-url/${extension}`)
        .then(response => {
          const record = response.data
          this.uploadImgS3(fd, reader, file, record)
        })
        .catch(error => {
          
        })
    },
    checkFullName(fullname) {
      let message = ''
      let result = false
      const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?0-9]+/

      if (!(fullname.length > 2)) {
        message = 'A valid fullname is required (only letters allowed)'
        result = true
      }

      if (format.test(fullname)) {
        message = 'A valid fullname is required (only letters allowed)'
        result = true
      }

      return [result, message]
    },

    viewUploadedResume() {
      // Math.random() is to prevent image being cache and display the previous file, when user upload or changed the resume file 
      // const fileUrl = `${this.accountInfo.resume_file_url}?${Math.random(0, 30000000)}`
      const fileUrl = `${this.accountInfo.resume_file_url}`

      if (this.accountInfo.resume_file && this.accountInfo.resume_file.endsWith('pdf')) {
        this.document_type = 'pdf'
        this.file_source = `${fileUrl}`
      } else {
        this.document_type = 'docs'
        this.file_source = `https://view.officeapps.live.com/op/embed.aspx?src=${fileUrl}`
      }
      this.view_resume_popup = true
    },

    viewUploadedOwnershipDocument() {
      const fileUrl = `${this.companyInfo.ownership_document_file_url}`

      if (this.companyInfo.ownership_document_file.endsWith('pdf')) {
        this.document_type = 'pdf'
        // this.ownership_document_source = `${fileUrl}?random=${new Date().getTime()}`
        this.ownership_document_source = `${fileUrl}`
      } else {
        this.document_type = 'docs'
        this.ownership_document_source = `https://view.officeapps.live.com/op/embed.aspx?src=${fileUrl}`
      }
      this.view_ownership_document_popup = true
    },
    manualResume() {
      this.notify_resume_parser = false
    },
    goToResume() {
      if (this.normal) {
        this.activeTab = 0
        this.notify_resume_parser = false
        this.$toast(
          {
            component: this.$toastContent,
            props: {
              title: 'Resume Upload',
              icon: 'AlertCircleIcon',
              text: 'Click on the `Upload your resume` or`Update your resume` button to upload your resume file. Our system will extract your education and employment information if added.',
              variant: 'warning',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }
    },
    changeOfTab(current_step) {
      if (this.normal) {
        if (current_step === 3) {
          this.activeTab = 1
          setTimeout(() => { // wait for a bit for the button to appear
            if (this.$refs.tourguide) {
              this.$refs.tourguide.currentStep = 4
            }
          }, 300)

          if (this.$isEmpty(this.employmentInfo) && this.$isEmpty(this.accountInfo.resume_file)) {
            // WIREFRAME this.notify_resume_parser = true
            this.notify_resume_parser = false
          }
        } else if (current_step === 6) {
          this.activeTab = 2
          setTimeout(() => { // wait for a bit for the button to appear
            if (this.$refs.tourguide) {
              this.$refs.tourguide.currentStep = 7
            }
          }, 300)

          if (this.$isEmpty(this.educationInfo)) {
            // WIREFRAME this.notify_resume_parser = true
            this.notify_resume_parser = false
          }
        }
      }
    },
    onTab() {
      if (this.normal) {
        if (this.activeTab === 1) {
          if (this.$refs.tourguide) {
            this.$refs.tourguide.currentStep = 4
          }

          if (this.employmentInfo.length === 0) {
            // WIREFRAME this.notify_resume_parser = true
            this.notify_resume_parser = false
          }
        } else if (this.activeTab === 2) {
          if (this.$refs.tourguide) {
            this.$refs.tourguide.currentStep = 7
          }

          if (this.educationInfo.length === 0) {
            this.notify_resume_parser = true
          }
        } else if (this.activeTab === 0) {
          if (this.$refs.tourguide) {
            this.$refs.tourguide.currentStep = 2
          }
        }
      }
    },
    finishTour() {
      const { id } = this.$store.state.auth.ActiveUser
      const record = { tour_finished: true, tour_finished_on: new Date(), user_id: id }
      this.$http
        .put('/api/account-config', record)
        .then(response => {
          const { config } = response.data
          
          this.finished_tour = config.tour_finished
        })
        .catch(error => {})
    },

    sendCompletedProfile() {
      const all_data = { completed_profile: true }
      this.$http
        .put('/api/account-config', { data: all_data })
        .then(response => {
          const config = response.data

          if (!response.data.first_time) {
            this.profilePopup = config.completed_profile
          }

          this.$http
            .put('/api/account-config', { data: { first_time: true } })
            .then(response => {
              if (!response.data.first_time) {
                this.profilePopup = response.data.completed_profile
              }
            })
            .catch(error => {})
          this.finished_tour = config.tour_finished
          this.e2i_recommendation = config.allow_recommendation
        })
        .catch(error => {})
    },
    updateConfigInfo() {
      const all_data = { e2i_recommendation: this.e2i_recommendation }
      this.$http
        .put('/api/account-config', { data: all_data })
        .then(response => {
          const { config } = response.data

          this.finished_tour = config.tour_finished
          this.e2i_recommendation = config.allow_recommendation
        })
        .catch(error => {})
    },

    getConfigInfo() {
      this.$http
        .get('/api/account-config')
        .then(response => {
          const { config } = response.data

          if (config.first_time === false) {
            this.profilePopup = config.completed_profile
          }
          this.finished_tour = config.tour_finished
          this.e2i_recommendation = config.allow_recommendation
          // this.sendCompletedProfile();
        })
        .catch(error => {})
    },

    stayOnPage() {
      this.profilePopup = false
      this.sendCompletedProfile()
    },
    tourDone(currentStep) {},
    gotoEdit(event) {
      if (this.$route.name === 'profile') {
        this.$router.push({ name: 'edit-profile' })
      } else {
        this.$router.push({ name: 'edit-corporate' })
      }
      // this.$emit('clicked', true)
    },
    gotoView(event) {
      if (this.$route.name === 'edit-corporate') {
        this.$router.push('/corporate-profile')
      } else {
        this.$router.push({ name: 'profile' })
      }
    },
    telegramLogout() {
      this.$http
        .put('/api/user', {
          user: {
            telegram_id: 100,
          },
        })
        .then(response => {
          // this.sendMenuUpdates(this.telegram_id, this.company_name);
          this.accountInfo.telegram_id = response.data.telegram_id

          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: 'Profile Update',
                icon: 'AlertCircleIcon',
                text: 'You have successfully disconnected from telegram!',
                variant: 'success',
              },
            },

          )
          this.has_logged_telegram = false
          // window.location.reload();
        })
        .catch(error => {
          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: 'Profile Update',
                icon: 'AlertCircleIcon',
                text: 'Could not disconnect from telegram, please try again later!',
                variant: 'danger',
              },
            },
            { timeout: this.$longestTimeout },
          )
        })
    },
    afterTelegram(user) {
      this.$http
        .put('/api/user', {
          user: {
            telegram_id: user.id,
            image: user.photo_url,
          },
        })
        .then(response => {
          this.accountInfo.telegram_id = response.data.telegram_id
          this.has_logged_telegram = true
          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: 'Profile Update',
                icon: 'AlertCircleIcon',
                text: 'Your telegram ID has been updated successfully!',
                variant: 'success',
              },
            },
            { timeout: this.$longestTimeout },
          )
        })
        .catch(error => {
          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: 'Profile Update',
                icon: 'AlertCircleIcon',
                text: 'Could not update your telegram ID, please try again later!',
                variant: 'danger',
              },
            },
            { timeout: this.$longestTimeout },
          )
        })
    },
    updateFiles(input) {
      const { id } = this.$store.state.auth.ActiveUser
      this.$toast(
        {
          component: this.$toastContent,
          props: {
            title: 'Resume Upload',
            icon: 'AlertCircleIcon',
            text: 'Resume upload in progress.',
            variant: 'warning',
            progress: 0,
            progressId: 'uploadResume',
          },
        },
        { timeout: this.$fileUploadTimeout },
      )
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {}
        const fd = new FormData()
        fd.append('file', input.target.files[0])
        // delete this.$http.defaults.headers.common["Authorization"];

        this.$http
          .post(`/api/upload-resume/${email}`, fd, {
            onUploadProgress(progressEvent) {
              const uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
              const parent = document.querySelector('#uploadResume')
              parent.querySelector('.progress-bar').style = `width: ${uploadPercentage}%;`
            },
          })
          .then(response => {
            this.resume_file = response.data.filename
            // let image_url = response.data.data.display_url;
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Resume Upload',
                  icon: 'AlertCircleIcon',
                  text: response.data.message,
                  variant: 'success',
                },
              },
              { timeout: this.$longestTimeout },
            )
            this.accountInfo.resume_file = response.data.filename
            this.isLoading = true

            this.getEmployment()
            this.getEducation()
          })
          .catch(error => {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Resume Upload',
                  icon: 'AlertCircleIcon',
                  text: 'Resume upload failed. Try again later.',
                  variant: 'warning',
                },
              },
              { timeout: this.$longestTimeout },
            )
          })
        reader.readAsDataURL(input.target.files[0])
      }
    },

    uploadImgS3(fd, reader, file, s3_result) {
      const fileName = s3_result.filename
      const s3_response = s3_result.result
      // fd.append('key', s3_response.fields.key)
      // fd.append('AWSAccessKeyId', s3_response.fields.AWSAccessKeyId)
      // fd.append('policy', s3_response.fields.policy)
      // fd.append('signature', s3_response.fields.signature)
      const content = {
        key: s3_response.fields.key,
        AWSAccessKeyId: s3_response.fields.AWSAccessKeyId,
        policy: s3_response.fields.policy,
        signature: s3_response.fields.signature,
      }
      const awsUrl = s3_response.url.substr(0, s3_response.url.length - 1)
      const urlParams = `?AWSAccessKeyId=${s3_response.fields.AWSAccessKeyId}
      &key=${s3_response.fields.key}&policy=${s3_response.fields.policy}
      &signature=${s3_response.fields.signature}`
      const fullUrl = `${awsUrl}${urlParams}`
      // const url = this.profileType
      //   ? '/api/user-profile-image'
      //   : '/api/upload-company-pic'

      const { token } = this.$store.state.auth.ActiveUser
      this.$http.defaults.headers.common.Authorization = `Token ${token}`
    },
    updateCurrImg(input) {
      const { id } = this.$store.state.auth.ActiveUser
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.readAsDataURL(input.target.files[0])
        const outerThis = this
        const fd = new FormData()
        const img = new Image()
        img.src = window.URL.createObjectURL(input.target.files[0])
        img.onload = () => {
          if (img.height !== 400 || img.width !== 400) {
            outerThis.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Image size error',
                  icon: 'AlertCircleIcon',
                  text: 'The accepted width and height of the image is 400px and 400px respectively.',
                  variant: 'danger',
                },
              },
              { timeout: this.$longestTimeout },
            )
            return false
          }

          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: 'Upload Status',
                icon: 'AlertCircleIcon',
                text: 'Uploading image in progress.',
                variant: 'warning',
                progress: 0,
                progressId: 'uploadImage',
              },
            },
            { timeout: this.$fileUploadTimeout },
          )
          const file = input.target.files[0]

          const extension = file.name.split('.').pop()
          singleUpload(file, 'user-profile-images', `${id}.${extension}`, outerThis = this)
          // this.getPresignedUploadUrl(extension, fd, reader, file)
        }
      }
    },

    updateEmployment(type, _id, index) {
      this.isLoading = true
      const specific_item = this.employmentInfo[index]
      
      if (specific_item) {
        specific_item.type = type
        this.$http
          .put('/api/employment-data', specific_item)
          .then(response => {
            if (type === 'insert' || type === 'update') {
              if (Object.keys(response.data.output).length > 0) {
                if (response.data.output.id) {
                  this.employmentInfo[index].id = response.data.output.id
                }
              }
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: 'Employment Data Update',
                    icon: 'AlertCircleIcon',
                    text: response.data.message,
                    variant: 'success',
                  },
                },
              )
            } else {
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: 'Employment Data Deletion',
                    icon: 'AlertCircleIcon',
                    text: 'Data deleted successfully!',
                    variant: 'danger',
                  },
                },
                { timeout: this.$longestTimeout },
              )
              this.employmentInfo.splice(index, 1)
            }
            this.isLoading = false
            this.latestUpdates.employment = false
          })
          .catch(error => {})
      }
    },
    updateEducation(type, _id, index) {
      this.isLoading = true
      const specific_item = this.educationInfo[index]
      
      if (specific_item) {
        specific_item.type = type
        specific_item.data_id = _id

        this.$http
          .put('/api/education-data', specific_item)
          .then(response => {
            if (type === 'insert' || type === 'update') {
              if (Object.keys(response.data.output).length > 0) {
                if (response.data.output.id) {
                  this.educationInfo[index].id = response.data.output.id
                }
              }
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: 'Education Data Update',
                    icon: 'AlertCircleIcon',
                    text: response.data.message,
                    variant: 'success',
                  },
                },
              )
            } else {
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: 'Education Data Deletion',
                    icon: 'AlertCircleIcon',
                    text: 'Data deleted successfully!',
                    variant: 'danger',
                  },
                },
              )
              this.educationInfo.splice(index, 1)
            }
            this.isLoading = true
            this.latestUpdates.education = false
          })
          .catch(error => {})
      }
    },

    saveCompany() {
      // if (this.poc.phone_number.toString().length !== 8) {
      //   // this.$vs.notify({
      //   //   time: 2000,
      //   //   title: "Invalid phone number",
      //   //   text: "The company phone number that you have filled is invalid.",
      //   //   iconPack: "feather",
      //   //   icon: "icon-alert-circle",
      //   //   color: "danger",
      //   //   position: "top-right",
      //   // });
      // }
      this.isLoading = true

      this.$http
        .post('/api/poc', {
          poc_info: {
            data: this.poc,
          },
        })
        .then(response => {
          if (response.data.success) {
            this.$toastSuccess('Company Details Update', 'Company details have been updated successfully!')
          }
          this.isLoading = true
          this.latestUpdates.poc = false
        })
        .catch(error => {
          this.$handleErrorResponse(error, 'Company Details Update', 'Company details update has failed!')
        })
    },
    sendMenuUpdates(old_id, company_name) {
      this.$http
        .post('/api/send-menu', {
          data: { telegram_id: old_id, company_name },
        })
        .then(response => {
          if (response.data.success) {
          }
        })
        .catch(error => {})
    },
    checkProfileCompleteness(response_data) {
      this.current_event = localStorage.getItem('current_event')
        ? localStorage.getItem('current_event')
        : ''

      if (!response_data.profile_status.incomplete) {
        const all_data = { completed_profile: true }
        this.$http
          .put('/api/account-config', { data: all_data })
          .then(response => {
            const { config } = response.data

            if (config.first_time === false) {
              this.profilePopup = true
            }

            this.$http
              .put('/api/account-config', { data: { first_time: true } })
              .then(response => {
                const { config } = response.data
              })
              .catch(error => {})

            this.finished_tour = config.tour_finished
            this.e2i_recommendation = config.allow_recommendation
          })
          .catch(error => {})
      }
    },
    saveProfile(company = false, password = false) {
      if (typeof company === 'boolean') {
        if (this.$route.name === 'edit-corporate') {
          company = true
        }
        let proceed = true
        const { token } = this.$store.state.auth.ActiveUser
        this.$http.defaults.headers.common.Authorization = `Token ${token}`

        const preferred_job_type = []

        if (this.checkFullName(this.accountInfo.fullname)[0]) {
          proceed = false
        }
        
        if (company === false) {
          // this.accountInfo.phone_number = this.accountInfo.phone_number.toString()
          // if (this.accountInfo.phone_number.toString().length !== 8 && this.$route.name === 'edit-profile') {
          //   this.$toast(
          //     {
          //       component: this.$toastContent,
          //       props: {
          //         title: 'Invalid phone number',
          //         icon: 'AlertCircleIcon',
          //         text: 'The phone number that you have filled is invalid.',
          //         variant: 'danger',
          //       },
          //     },
          //     { timeout: this.$longestTimeout },
          //   )
          //   proceed = false
          // }

          const form_data = this.accountInfo

          form_data.password = null
          form_data.old_password = null
          if (password) {
            form_data.password = this.userPassword
            form_data.old_password = this.oldPassword
          }

          form_data.preferred_job_type = preferred_job_type
          delete form_data.phone_number
          if (proceed) {
            this.$http
              .put('/api/user', {
                user: form_data,
              })
              .then(response => {
                if (response.data.errors) {
                  if (response.data.errors[0] === 'User not found') {
                    localStorage.removeItem('userInfo')
                    localStorage.removeItem('email')
                    localStorage.removeItem('accessToken')
                    this.$router.push('/auth/login')
                  }
                } else if (response.data.password_error) {
                  this.$toast(
                    {
                      component: this.$toastContent,
                      props: {
                        title: 'Password Error',
                        icon: 'AlertCircleIcon',
                        text: response.data.message,
                        variant: 'danger',
                      },
                    },
                    { timeout: this.$longestTimeout },
                  )
                } else {
                  this.accountInfo.avatar = response.data.profile_image
                  if (this.accountInfo.avatar) {
                    const imageUrl = `${this.$bucketUrl}/${this.accountInfo.avatar}`
                    this.accountInfo.avatar = imageUrl
                    this.accountInfo.telegram_id = response.data.telegram_id
                  }

                  this.accountInfo.preferred_position = response.data.preferred_position

                  if (response.data.telegram_id !== 0) {
                    this.has_logged_telegram = true
                  }
                  this.updateConfigInfo()
                  this.$toast(
                    {
                      component: this.$toastContent,
                      props: {
                        title: 'Profile Update',
                        icon: 'CheckCircleIcon',
                        text: 'Your profile has been updated successfully!',
                        variant: 'success',
                      },
                    },
                  )
                  this.userPassword = ''
                  this.confirmPassword = ''
                  this.oldPassword = ''
                  
                  this.$store.commit('auth/UPDATE_USER_INFO', response.data)
                  this.latestUpdates.account = true
                }

                this.isLoading = false
                // this.$emit("updateProfile", true);
              })
              .catch((error) => {
                
              })
          }
        } else {
          const companyDetails = this.companyInfo
          const record = {
            id: companyDetails.id,
            ownership_document_file: companyDetails.ownership_document_file,
            company_name: companyDetails.company_name,
            uen_no: companyDetails.uen_no,
            company_image: companyDetails.company_image,
            company_summary: companyDetails.company_summary,
            licence_number: companyDetails.licence_number,
          }
          this.$http
            .post('/api/company-info', { company_info: record })
            .then(response => {
              const { success } = response.data

              if (success) {
                this.companyInfo.avatar = null
                this.companyInfo.avatar = response.data.message.company_image_url

                this.$toast(
                  {
                    component: this.$toastContent,
                    props: {
                      title: 'Profile Update',
                      icon: 'CheckCircleIcon',
                      text: 'Your company profile has been updated successfully!',
                      variant: 'success',
                    },
                  },
                )
              }
            })
            .catch(error => {
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: 'Profile Update',
                    icon: 'CheckCircleIcon',
                    text: 'Your company profile has failed. Try again later!',
                    variant: 'success',
                  },
                },
              )
            })

        }
      }
    },
    createNewEmployment() {
      this.employmentInfo.push({
        company_name: null,
        position: null,
        description: null,
        start_date: null,
        end_date: null,
        id: null,
      })
      window.scrollTo(
        0, document.body.scrollHeight || document.documentElement.scrollHeight,
      )
    },

    deleteEmployment(item_id, item_index) {
      if (item_id === 0) {
        this.employmentInfo.splice(item_index, 1)
      } else {
        this.updateEmployment('delete', item_id, item_index)
      }
    },

    createNewEducation() {
      this.educationInfo.push({
        school_name: null,
        field_of_study: null,
        start_date: null,
        end_date: null,
        id: null,
      })
      window.scrollTo(
        0,
        document.body.scrollHeight || document.documentElement.scrollHeight,
      )
    },

    deleteEducation(item_id, item_index) {
      if (item_id === 0) {
        this.educationInfo.splice(item_index, 1)
      } else {
        this.updateEducation('delete', item_id, item_index)
      }
    },

    getPOC() {
      this.$http
        .get('/api/poc')
        .then(response => {
          const poc = response.data.data
          if (poc) {
            this.poc = poc
          }
        })
        .catch(error => {})
    },

    getEmployment() {
      this.$http
        .get('/api/employment-data')
        .then(response => {
          this.employmentInfo = response.data.message

          this.isLoading = false
          // this.$forceUpdate();
        })
        .catch(error => {})
    },
    getEducation() {
      this.$http
        .get('/api/education-data')
        .then(response => {
          this.educationInfo = response.data.message

          this.isLoading = false
          // this.$forceUpdate();
        })
        .catch(error => {})
    },

    selectHandlers: (map, vm) => ({
      ...map,
      50: e => {
        e.preventDefault()
      },
    }),
  },
}
</script>

<style lang="scss">

  .flatpickr-monthDropdown-month{
    background-color: #fff!important;
  }
.v-tour {
  .v-step {
    background-color: rgba(var(--vs-dark), 1);
    border-radius: 0.5rem;
    padding: 1.5rem;
    filter: drop-shadow(0 0 7px rgba(0, 0, 0, 0.5));

    .v-step__arrow {
      border-color: rgba(var(--vs-dark), 1);
    }

    .b-button-border:not(.btn-tour-next):not(.btn-tour-finish) {
      border-color: rgba(255, 255, 255, 0.5) !important;
    }
  }
}

.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.4);
}
</style>
